<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
      :cell-classes="cellClasses"
    />
  </div>
</template>

<script>
import models from "@/models";

export default {
  name: "IncomingOrders",
  components: {},
  data: function() {
    return {
      model: models.warehouse.invoices,
      filters: ["Warehouse", "Invoice Date", "Check Status", "Pay Status"],
      tableColumns: [
        "ID",
        "QB Number",
        "Invoice Date",
        "QB Date",
        "Distributor",
        "Terms",
        "Pay Status",
        "Total",
        "QB Amount",
        "Discount",
        "Exchange Rate",
        "Currency",
        "PG",
        "WG",

        "Check Status"
      ],
      cellClasses: {
        "Check Status": [
          {
            class: "bg-danger",
            condition: row => row["Check Status"] === "ERROR"
          },
          {
            class: "bg-success",
            condition: row => row["Check Status"] === "OK"
          }
        ],
        "Pay Status": [
          {
            class: "bg-danger",
            condition: row => row["Pay Status"] === "OVERDUE"
          },
          {
            class: "bg-success",
            condition: row => row["Pay Status"] === "OK"
          }
        ]
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>

<style></style>
